<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('settings.advanced_settings')"
      :sub-title="$t('settings.advanced_settings_employee')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-md-2 px-0 no-gutters">
        <b-col md="6" class="px-0">
          <b-card no-body class="cursor-pointer wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-reg-18 text-font-main">
                {{ $t('common.adjustments_financial_ratios') }}
              </h5>
            </b-card-header>
            <wameed-form refs="" @submitAction="submitUpdateProfile">
              <template slot="form-content" slot-scope="{ invalid }">
                <b-card-body
                  class="position-relative py-0 justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap pt-4">
                    <b-col cols="12">
                      <text-input
                        v-model="formData.tax"
                        :label="$t('common.value_added') + ' % '"
                        input-classes="text-med-14 text-font-main"
                        name="tax"
                        rules="required|numeric"
                        type="number"
                        placeholder="----------------"
                      />
                    </b-col>
                    <b-col cols="12">
                      <text-input
                        v-model="formData.platform_dues"
                        :label="$t('common.win_platform') + ' % '"
                        input-classes="text-med-14 text-font-main"
                        name="platform_dues"
                        rules="required|numeric"
                        type="number"
                        placeholder="----------------"
                      />
                    </b-col>
                    <b-col cols="12">
                      <text-input
                        v-model="formData.management_fee"
                        :label="$t('common.management_fee')"
                        input-classes="text-med-14 text-font-main"
                        name="management_fee"
                        rules="required|numeric"
                        type="number"
                        placeholder="----------------"
                      />
                    </b-col>
                  </div>
                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <hr />
                  <div class="d-flex justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                        classes="  text-med-14 text-white rounded-10"
                        :title="$t('common.save_edited')"
                        :disabled="invalid"
                        type="submit"
                        variant="main"
                      />
                    </div>
                    <div class="px-4 py-3">
                      <wameed-btn
                        classes="  text-med-14 text-font-secondary rounded-10 "
                        :title="$t('common.cancel')"
                        type="button"
                        variant="gray"
                        onclick="history.back()"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </template>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import WameedAsideModal from '@/components/WameedAsideModal.vue';
import WameedSwitch from '@/components/WameedSwitch.vue';
import TextEditor from '@/components/TextEditor.vue';
import DetailsForm from '@/components/pages/setting/company-information/DetailsForm.vue';
import SocialMediaForm from '@/components/pages/setting/company-information/SocialMediaForm.vue';
import PageHeader from '@/components/PageHeader.vue';
import ChangePassword from '@/components/pages/setting/company-information/ChangePassword.vue';
import TextInput from '@/components/TextInput.vue';
import TextArea from '@/components/TextArea.vue';
import MultiImagesUpload from '@/components/multiImagesUpload.vue';

export default {
  components: {
    WameedBtn,
    WameedForm,
    PageHeader,
    TextInput,
  },
  data() {
    return {
      formData: {
        tax: '',
        platform_dues: '',
        management_fee: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getAdvancedSettings: 'getAdvancedSettings',
    }),
  },
  watch: {
    getAdvancedSettings: {
      handler(newVal) {
        if (newVal) {
          this.formData = {
            tax: newVal.tax.toString(),
            platform_dues: newVal.platform_dues.toString(),
            management_fee: newVal.management_fee.toString(),
          };
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadAdvancedSettings();
    this.watchAdvancedSettings();
  },
  methods: {
    ...mapActions({
      loadAdvancedSettings: 'loadAdvancedSettings',
      updateAdvancedSettings: 'updateAdvancedSettings',
    }),
    watchAdvancedSettings() {
      if (this.getAdvancedSettings) {
        this.formData = {
          tax: this.getAdvancedSettings.tax.toString(),
          platform_dues: this.getAdvancedSettings.platform_dues.toString(),
          management_fee: this.getAdvancedSettings.management_fee.toString(),
        };
      }
    },
    submitUpdateProfile() {
      this.updateAdvancedSettings({
        tax: this.formData.tax,
        platform_dues: this.formData.platform_dues,
        management_fee: this.formData.management_fee,
      });
    },
  },
};
</script>
